import React from "react";
import Page from "../../components/Page";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image"

export default function SMART({ data }) {
  const image = data.image.childImageSharp.gatsbyImageData;

  const title = "Hur sätter man smarta mål? (med exempel)"

  return (
    <>
      <Page title={title} description="En mycket vanlig modell för att sätta mål är SMART, modellen kan användas till att sätta många olika typer av mål. Alltifrån projekt, försäljning till din egna personliga utveckling. Men vad innebär egentligen smart-modellen och vad är ett smart mål?" image={data.image.src}>
        <div className="container px-5 py-6 mx-auto">
          <article className="blogPost">
            {image && <GatsbyImage image={image} className="mb-5 rounded-xl" alt="smarta mål" />}
            <h1 className="font-bold text-4xl md:text-5xl text-gray-900 leading-tight mb-8">{title}</h1>
            <p>
              Det är viktigt att vi har mål på grund av flera anledningar. Mål ger oss en riktning till vart vi vill ta oss i livet och kan ge oss ett starkt varför. Mål kan även motivera oss och användas för att skapa din stora bild till vart du vill ta dig i livet.
            </p>
            <p>En mycket vanlig modell för att sätta mål är SMART, som kan användas till att sätta många typer av målsättning. Alltifrån projekt, försäljning till din egna personliga utveckling. Men vad innebär egentligen smart-modellen och vad är ett smart mål?</p>
            <h2>Vad är ett smart mål?</h2>
            <p>Smarta mål bygger på fem principer; specifikt, mätbart, accepterat, realistiskt och tids-relaterat. Tillsammans bildar dessa fem principer ordet SMART och det är anledning till varför det kallas för smarta mål. </p>
            <p>Smart modellen kan hjälpa oss att arbeta mer systematiskt med vår målsättning vilket kan öka sannolikheten för att vi uppnår våra mål och dessutom bidra med mycket motivation. De fem principer som smarta mål kännetecknas av:</p>
            <ul>
              <li><b>Specifikt - </b>Specificera vad som skall uppnås.</li>
              <li><b>Mätbart - </b>Kvantifiera eller föreslå en indikator på att mäta framsteg.</li>
              <li><b>Accepterat - </b>Målet skall accepteras av alla inblandade. </li>
              <li><b>Realistiskt - </b>Målet skall vara realistiskt och nåbart givet dina nuvarande resurser. </li>
              <li><b>Tids-relaterat – </b>Specificera när målet skall vara uppnått.</li>
            </ul>
            <p>De olika principer beskrivs mer ingående nedan.</p>
            <h3>1. Specifikt</h3>
            <p>
              Att målet är specifikt innebär att det är tydligt och konkret. Du vet vad du skall bör fokusera på och varför. Det är viktigt att vi har klarheter i våra mål så att vi vet vad som skall uppnås. Om målet är kopplat till ett företag eller en kund blir detta extra viktigt eftersom vi behöver vara specifika med vad vi skall leverera. Om det är ett personligt mål så kan man ställa sig själv frågor såsom:
            </p>
            <ul>
              <li>Vad vill jag åstadkomma?</li>
              <li>Varför är mitt mål viktigt?</li>
            </ul>
            <h3>2. Mätbart</h3>
            <p>
              Målet skall också vara mätbart så att vi kan följa och mäta våra framsteg. Detta är bland annat bra för att vi skall kunna bibehålla motivationen. Att mäta våra resultat och framsteg kan dessutom göra oss mer fokuserade och exalterade när vi ser att vi närmar oss vårt mål.
            </p>
            <p>I detta steget ska man också hitta sätt som man kan mäta sitt mål på. För att nämna ett exempel, säg att du vill starta en blogg. Då kan du mäta antalet artiklar du publicerar per vecka, eller antalet läsare på bloggen varje månad. Hitta olika sätt som du kan mäta ditt mål på.</p>
            <h3>3. Accepterat</h3>
            <p>
              Målet ska accepteras hos alla inblandade, och detta är extra viktigt om du arbetar i ett team på ett företag. Men ditt mål måste också accepteras av dig själv, och det är exemplevis viktigt att vi känner att vi sätter upp ett nåbart mål. Vi måste även känna att vi är rätt person för att nå vårt mål.
            </p>
            <h3>4. Realistiskt</h3>
            <p>
              Målet skall också vara realistiskt och nåbart, men det skall också samtidigt vara något som betyder något för dig. Vi bör också känna att målet är realistiskt givet våra nuvarande resurser.
            </p>
            <p>Ett mål bör också tänja på dina gränser och utmana dig men målet ska som sagt ändå vara möjligt. Detta steget är också mycket kopplat till vilken tidsram du har på målet. Om du har en lång tidsplan kommer mer vara möjligt jämfört med om du hade haft en kortare tid för att nå målet. Var även försiktig med att sätta upp mål som du själv inte har någon kontroll över, detta kan vara mål som att "Jag skall få en befordran". </p>
            <h3>5. Tids-relaterat</h3>
            <p>
              Alla mål skall också ha ett slutdatum, en deadline när målet skall vara avklarat. Det är viktigt att vi sätter ett slutdatum på våra mål eftersom det kommer hjälpa oss i vår målplanering. Ett konkret datum göra att vi vet när vi skall ha uppnått mål och kan hjälpa oss att veta hur mycket arbete vi kommer behöva lägga ner varje dag eller vecka.
            </p>
            <p>Vi kan också dela upp vårt mål i flera mindre delmål så att vi kan få en bättre överblick och sätta slutdatum för varje delmål. Detta hjälper mycket i vår målplanering.</p>
            <GatsbyImage
              image={data.image2.childImageSharp.gatsbyImageData}
              className="imageFullWidth"
              alt="smarta mål"
            />
            <h2>Hur sätter man smarta mål? (steg för steg)</h2>
            <p>Man kan enkelt sätta mål genom att gå igenom de fem principerna för smarta mål; specifikt, mätbart, accepterat, realistiskt och tids-relaterat. Nedan kan du se två exempel på hur man kan sätta smarta mål steg för steg. </p>
            <h3>Exempel 1. Starta en blogg</h3>
            <p>Säg att du vill skapa en blogg inom mode och design. Första steget är att göra målet specifikt, och det kan vi göra genom att definiera vårt mål enligt följande. Målet är att skapa en blogg inom mode och design för att kunna skriva om det jag brinner för, och samtidigt tjäna en sidoinkomst.</p>
            <p>Andra steget är sedan att hitta sätt som man kan mäta sitt mål på. Ett blogg kan vi göra mätbar på flera sätt. För att nämna ett exempel så kan vi bestämma oss för att räkna antalet besökare på bloggen och antalet artiklar vi publicerar. Med målet att publicera en artikel per vecka och nå 3000 besökare per månad.</p>
            <p>Tredje steget är därefter att målet skall vara accepterat av alla inblandade. Eftersom vi är den enda personen som är inblandade i målet är svaret på denna frågan ja. Vi anser även att målet är nåbart och att vi är rätt person för att utföra det.</p>
            <p>Fjärde steget är sedan att fundera om målet är realistiskt och nåbart. Eftersom vi skall starta en blogg kan vi i detta steget ställa oss frågor såsom; är det rimligt att nå 3000 per månad på ett år? Är det realistiskt att publicera en artikel per vecka?</p>
            <p>Femte steget är sedan att göra målet tids-relaterat och det har vi redan gjort genom att säga att vi skall uppnå målet om ett år. Vi har dessutom satt upp en handlingsplan genom att planerat att vi skall publicera ett artikel per vecka. </p>
            <GatsbyImage
              image={data.image3.childImageSharp.gatsbyImageData}

            />
            <h3>Exempel 2. Bli mer hälsosam</h3>
            <p>Säg att du vill bli mer hälsosam. Första steget är att göra målet specifikt, och det kan vi göra genom att definiera vårt mål. Vad innebär det att vara hälsosam? Varför vill jag bli mer hälsosam? På dessa frågor kommer vi fram till att kosten och träning är mycket viktiga. Och att vi vill bli mer hälsosamma för att leva ett längre liv. Målet är alltså att bli mer hälsosam genom att förändra kosten och träna kontinuerligt. </p>
            <p>Andra steget är sedan att göra målet mätbart. När det gäller vårt hälsomål kan vi exempelvis göra målet mätbart genom att definiera hur många gånger vi ska träna per vecka, eller antalet kg som vi gå ner i vikt. Kanske bestämmer vi oss för att vi skall börja träna 3 gånger per vecka och att vi vill gå ner 10kg i vikt på 3 månader.</p>
            <p>Tredje steget är därefter att målet skall vara accepterat av alla inblandade. Eftersom detta målet gäller kosten kommer det också att gälla alla andra i ditt hushåll och då kan det vara viktigt att ha den diskussion, så att du ser till att din familj stöttar dig i ditt mål. </p>
            <p>Fjärde steget är sedan att fundera om målet är realistiskt och nåbart. Är det rimligt för oss att träna 3 gånger i veckan och gå ner 10 kg i vikt på 3 månader? Kanske märker vi här att det inte är nåbart att göra under 3 månader, och vi kan då ändra vårt mål så att det stäcker sig över 6 månader istället för att göra det mer realistiskt.</p>
            <p>Femte steget är sedan att göra målet tids-relaterat och det har vi redan gjort genom att säga att vi skall uppnå målet inom 6 månader. Vi har dessutom satt upp en handlingsplan genom att vi skall träna 3 gånger per vecka och diskuterat med familjen hur vi skall göra om kostvanorna. </p>
            <h2>Vad är fördelarna med smarta mål?</h2>
            <p>SMART kan vara en bra modell att sätta upp mål och kan ge mycket klarhet och motivation. Det kan också hjälpa dig att klarare definiera ditt mål som du själv vet vad du satsar efter. Modellen är dessutom mycket enkel att använda vilken är en stor fördel. </p>
            <p>Modellen är dessutom väldigt bra för att skapa mer struktur och ger dig ett konkret handlingsätt hur du skall du skall mäta och hantera dina mål. Att mäta sin mål och resultat är också något som är väldigt viktigt. Det kan bland annat öka motivation och ge oss lärdomar som vi kan använda oss av för att optimera processer så att vi kan nå våra mål fortare.</p>
            <h2>Vad är nackdelarna med smarta mål?</h2>
            <p>Nackdelen med SMART är att det inte passar för alla typer av mål. Exempelvis är smart modellen inte optimal om man vill sätt riktigt långsiktiga mål gällande sitt liv. Anledning till detta beror på väldigt långsiktiga mål kan sakna en deadline helt eller delvis, samt så kan de vara svåra att definiera.</p>
            <p>Vissa hävdar dessutom att modellen har en dålig flexibilitet, vilket många mål ofta kan behöva. När man arbetar för att nå sina mål kan det ibland behövas att man omdefinierar målen när man har mer information. Eller så kanske man inser att det man först ville uppnå inte var så viktigt trots allt.</p>
            <br />
            <br />
          </article>
        </div>
      </Page >
    </>
  );
}

export const pageQuery = graphql`
  query {
    image2: file(relativePath: { eq: "smartgoalsinfo.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    image: file(relativePath: { eq: "smartgoals.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
      image3: file(relativePath: { eq: "smartgoals.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
  }
`;
